/* @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap'); */

@tailwind base;
@tailwind components;
@tailwind utilities;


/* To apply a stroke-width to heroicons */
@layer base {
  /* stroke-width: 2 is a browser default and can be overridden with class="stroke-1" etc. */
  svg {
    stroke-width: 2;
  }

  path,
  circle,
  ellipse,
  line,
  polygon,
  polyline,
  rect {
      stroke-width: inherit;
  }

  @font-face {
    font-family: "VAGRoundedLt";
    src: url(/dist/fonts/vagrndlt.ttf) format("truetype");
  }
  @font-face {
    font-family: "VAGRoundedBold";
    src: url(/dist/fonts/vagrndbold.ttf) format("truetype");
  }
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
